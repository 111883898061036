<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>
import { getCookie , getDomain } from '@/utils';
export default {
  name: 'App',
  mounted(){
     this.cookieFun();
     this.environment();
  },
  methods:{
      //如果有cookie
      cookieFun(){
          let appid = getCookie('appid');
          let domain = getDomain();
          if(appid==domain||domain=='localhost'||domain=="device"){
            let token = getCookie('token');
            if(token && sessionStorage.isToken && JSON.parse(sessionStorage.isToken)){
                sessionStorage.setItem("isToken",false);
                sessionStorage.setItem("token",token);
                localStorage.setItem("token",token);
            }
          }else{
            sessionStorage.removeItem('token');
          }
      },
      //判断环境
      environment(){
          if(this.isWeixn()){
            sessionStorage.setItem("device", "wx");       //添加微信标识
          } else if (this.isWindows()){
            sessionStorage.setItem("device", "windows");      //添加windows标识
          } else if (this.isAndroid()){
            sessionStorage.setItem("device", "android");  //添加安卓标识
          }
      },
      //判断是否是微信
      isWeixn(){
          var ua = navigator.userAgent.toLowerCase();
          if(ua.match(/MicroMessenger/i)=="micromessenger") {
              return true;
          } else {
              return false;
          }
      },
      //判断是否是安卓设备
      isAndroid(){
          if(window["ReactNativeWebView"]){
              return true;
          }else{
              return false;
          }
      },
      //判断是否是win设备
      isWindows(){
          if (window.isElectron) {
              return true;
          }else{
              return false;
          }
      },

  }
}
</script>
