import axios from "@/utils/axios";
import problem from "@/utils/question";
import { question , setReport , setsetReportToken , lastInfo , bind_report } from "@/api";
import { ages , RandomString } from "@/utils";
import { audioPaly } from "@/utils";
import { fenxiing } from "@/utils/audio";
import { Notify } from 'vant';

let device = {
    namespaced: true,
    state: {
        problem:{
            renovate:true,
            data:[]
        },              // 问题数组
        tyle:1,         // 1是全部展示   2是一题一页展示
        page:0,         // 一页一题的页数，全部展示无效
        unique_token:RandomString(16),   //随机数,
        flow_base:0,
        show:false,
        token:'',
        loading:false
    },
    mutations: {
        //整理基础问题
        questions(state){
            //根据后端数据展示基础数据，默认[1,2,3,4]
            let flow = sessionStorage.getItem('flow');
            let flow_base = "";     //基础问题列表
            let flow_step = "";     //评估组合
            if(flow){
                flow_base = JSON.parse(flow).base_info;
                //获取问诊的展示方式
                state.tyle = JSON.parse(flow).flow_info.consultation_style;
                flow_step = JSON.parse(flow).flow_info.flow_step;
            }
            flow_base = flow_base?flow_base:[1,2,3,4];
            state.flow_base = flow_base.length;
            let matter = [];
            //查询需要展示的基础信息
            for(let i=0,n=problem.length;i<n;i++){
                for(let k=0,j=flow_base.length;k<j;k++){
                    if(problem[i].dockId==flow_base[k]){
                        problem[i].content.map((cont,index)=>{
                            if(typeof cont == 'string'||problem[i].content.length==5){
                                if(problem[i].content.length>1){
                                    problem[i].content = ['','','','00','00'];
                                    problem[i].date = 0;
                                }else{
                                    problem[i].content = [''];
                                }
                            }else{
                                problem[i].content[index].active = false;
                            }
                        })
                        matter.push(problem[i]);
                        break;
                    }
                }
            }
            //获取中医问诊题目
            if(!(flow_step=="5"||flow_step=="6"||flow_step=="1"||flow_step=="2")){
                this.dispatch('question/axiosData',{
                    data:{},
                    matter:matter
                })
            }else{
                state.problem.data = matter;
            }
        },
        //改变数值
        change(state,data){
            state[data.key] = data.value;
        },
        //清空
        closeFun(state){
            state.problem = { renovate:!state.problem.renovate, data:[] };
            state.tyle = 1;
            state.page = 0;
            state.unique_token = RandomString(16);
            state.flow_base = 0;
            state.show = false;
            state.token = '';
            state.loading = false;
        },
        //改变页码
        pageChange(state,data){
            //验证问题
            let page = state.page;
            let problem = state.problem.data;
            let bool = false;
            if(page<data.value){
                if(problem[page].no_select){
                    if(problem[page].id==-2){
                        if(problem[page].content[0]&&problem[page].content[1]&&problem[page].content[2]&&problem[page].content[3]&&problem[page].content[4]){
                            bool=true;
                        }
                    }else{
                        if(problem[page].content[0]){
                            if(problem[page].range[0]<=problem[page].content[0] && problem[page].content[0]<=problem[page].range[1]){
                                bool=true;
                            }else{
                                Notify(problem[page].msg);
                            }
                        }
                    }
                }else{
                    for(let i=0,n=problem[page].content.length;i<n;i++){
                        if(problem[page].content[i].active){
                            bool=true;
                        }
                    }
                }
                if(bool){
                    state.page = data.value;
                }
            }else{
                state.page = data.value;
            }
        },
        //改变problem的值
        problemChange(state,data){
            let problems = state.problem.data;
            if(problems[data.key].no_select){       
                if(problems[data.key].id==-2){      //年月日
                    if(data.value=='清空'){
                        problems[data.key].content = ['','','','00','00'];
                        problems[data.key].date = 0;
                    }else{
                        let vaule = problems[data.key].content;
                        let index = problems[data.key].date;
                        if(data.value<0){
                            if(index>4){
                                problems[data.key].date = 4;
                                index = 4;
                            }
                            let new_vaule = vaule[index].substr(0,vaule[index].length - 1);
                            problems[data.key].content[index]=new_vaule;
                            // 删除键回退
                            // if(new_vaule == '' && index!==0){
                            //     problems[data.key].date = problems[data.key].date-1;
                            // }
                        }else{
                            if(index>4){
                                problems[data.key].date = 4;
                                index = 4;
                            }
                            let new_vaule = `${(index==0 && problems[data.key].content[index].length<4)||problems[data.key].content[index].length<2?problems[data.key].content[index]:''}${data.value}`;
                            if(index!==vaule.length){
                                problems[data.key].content[index]=new_vaule;
                            }
                            if(index==0){   //年
                                if(problems[data.key].content[index].length==4){
                                    if(problems[data.key].content[index]>new Date().getFullYear()){
                                        problems[data.key].content[index] = '';
                                        problems[data.key].date = 0;
                                        Notify('请输入正确的年份！');
                                    }else{
                                        problems[data.key].date = problems[data.key].date+1;
                                    }
                                }
                            }else{          //月日时分
                                let date = problems[data.key].content[index];

                                if( date.length==1 && (index==1 && date>1 ) ||
                                    date.length==1 && (index==2 && date>3 ) ||
                                    date.length==1 && (index==3 && date>6 ) ||
                                    date.length==1 && (index==4 && date>6 ) 
                                ){
                                    problems[data.key].date = problems[data.key].date+1;
                                }else{
                                    if(index!==vaule.length && date.length==2){
                                        if(index==1 && (date>12 || date==0) ){           //月份
                                            problems[data.key].content[index] = '';
                                            problems[data.key].date = 1;
                                            Notify('请输入正确的月份！');
                                        }else if(index==2 && (date>31 || date==0) ){     //日期
                                            problems[data.key].content[index] = '';
                                            problems[data.key].date = 2;
                                            Notify('请输入正确的日期！');
                                        }else if(index==3 && date>24){     //时
                                            console.log(date);
                                            problems[data.key].content[index] = '';
                                            problems[data.key].date = 3;
                                            Notify('请输入正确的小时！');
                                        }else if(index==4 && date>60){     //分
                                            problems[data.key].content[index] = '';
                                            problems[data.key].date = 4;
                                            Notify('请输入正确的分钟！');
                                        }else{
                                            problems[data.key].date = problems[data.key].date+1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }else{          //输入框
                    if(data.value<0){       //删除
                        let value = `${problems[data.key].content[0]}`;
                        problems[data.key].content[0] = value.substr(0, value.length - 1);
                    }else if(data.value=='清空'){
                        problems[data.key].content[0] = "";
                    }else{      //添加
                        let value = `${problems[data.key].content[0]}${data.value}`;
                        if(value.length<4){
                            problems[data.key].content[0] = `${problems[data.key].content[0]}${data.value}`;
                        }
                    }
                }
            }else{
                //重复选择，撤销选择
                if(problems[data.key].content[data.index].active){
                    problems[data.key].content[data.index].active = false;
                }else{
                    //排它性
                    if(problems[data.key].content[data.index].is_single==1){
                        for(let i=0,n=problems[data.key].content.length;i<n;i++){
                            problems[data.key].content[i].active = false;
                        }
                    }else{
                        for(let i=0,n=problems[data.key].content.length;i<n;i++){
                            if(problems[data.key].content[i].is_single==1){
                                problems[data.key].content[i].active = false;
                            }
                        }
                    }
                    problems[data.key].content[data.index].active = data.value;
                }
            }
            state.problem = {
                renovate:!state.problem.renovate,
                data:problems
            };
        },
        //发送数据请求
        mainAxios(state,params){
            axios (`/api${question}`, params.data).then((data)=>{
                if(data.code==1){
                    state.problem = {
                        renovate:!state.problem.renovate,
                        data:params.matter.concat(data.data.list)
                    }
                    if(sessionStorage.token){   
                        this.dispatch('question/lastData'); //获取上一次的问题答案
                    }
                }
            });
        },
        //获取上一个问题答案
        lastAxios(state){
            axios (`/api${lastInfo}`).then((data)=>{
                if(data.code == 1){
                    let answer = JSON.parse(data.data.answer);
                    let ABC = ['A','B','C','D','E','F','G','H','I','J','K'];
                    let problem = state.problem.data;
                    answer.map((ans,index)=>{
                        ans.answer.map((wer)=>{
                            let num = ABC.indexOf(wer);
                            if(num>=0){
                                problem[index].content[num].active=true;
                            }else if(ans.id==-2){
                                let birthday = data.data.birthday.split(' ');
                                birthday = birthday[0].split('-').concat(birthday[1].split(':'));
                                problem[index].content = birthday;
                            }else{
                                problem[index].content[0]=wer;
                            }
                        })
                    });
                    state.problem = {
                        renovate: !state.problem.renovate,
                        data:problem
                    }
                }
            });
        },
        //提交问诊
        submitAxios(state,params){
            //整理提交
            let problems = state.problem.data;
            let age='',sex='',birthday='',height='',weight='',face_id='',
                tongue_id='',answer=[],equipment_code='',sys_company_id=0,
                unique_token='',carte_vital='',id_code='',user_name='',
                check_type=1,user_id=0,yao_account_id=0,server_type=0;

            let age_son,sex_son,height_son,weight_son,prob_son;
            let ABC = ['A','B','C','D','E','F','G','H','I','J','K'];
            let bool = true;    //验证填写
            let good = true;   //问题
            for(let i=0,n=problems.length;i<n;i++){
                let content = problems[i].content;
                switch(problems[i].id){
                    case -2:      //年龄
                        if(bool){
                            if(!(content[0] && content[1] && content[2] && content[3] && content[4])){
                                bool = false;
                                Notify('请填写出生年月！');
                                break;
                            }
                            
                            content[1] = Number(content[1])>9?Number(content[1]):`0${Number(content[1])}`;
                            content[2] = Number(content[2])>9?Number(content[2]):`0${Number(content[2])}`;
                            content[3] = Number(content[3])>9?Number(content[3]):`0${Number(content[3])}`;
                            content[4] = Number(content[4])>9?Number(content[4]):`0${Number(content[4])}`;

                            age = ages(`${content[0]}-${content[1]}-${content[2]}`);
                            birthday = `${content[0]}-${content[1]}-${content[2]} ${content[3]}:${content[4]}`
                            age_son = {
                                answer:[`${content[0]}-${content[1]}-${content[2]}`],
                                id:-2
                            }
                            answer.push(age_son);
                        }
                        break;
                    case -1:      //性别
                        if(bool){
                            if(!(content[0].active||content[1].active)){
                                bool = false;
                                Notify('请选择性别！');
                                break;
                            }
                            sex = content[0].active?1:2;
                            sex_son = {
                                answer:[`${content[0].active?'A':'B'}`],
                                id:-1
                            }
                            answer.push(sex_son);
                        }
                        break;
                    case -3:      //身高
                        if(bool){
                            if(!(content[0])){
                                bool = false;
                                Notify('请填写身高！');
                                break;
                            }
                            height = content[0];
                            height_son = {
                                answer:[`${content[0]}`],
                                id:-3
                            }
                            answer.push(height_son);
                        }
                        break;
                    case -4:      //体重
                        if(bool){
                            if(!(content[0])){
                                bool = false;
                                Notify('请填写体重！');
                                break;
                            }
                            weight = content[0];
                            weight_son = {
                                answer:[`${content[0]}`],
                                id:-4
                            }
                            answer.push(weight_son);
                        }
                        break;
                    case -5:      //身份证
                        if(bool){
                            if(!(content[0])){
                                bool = false;
                                Notify('请填写身份证！');
                                break;
                            }
                            id_code = content[0];
                        }
                        break;
                    case -6:      //医保卡
                        if(bool){
                            if(!(content[0])){
                                bool = false;
                                Notify('请填写医保卡！');
                                break;
                            }
                            carte_vital = content[0]; 
                        }
                        break;
                    case -7:      //姓名
                        if(bool){
                            if(!(content[0])){
                                bool = false;
                                Notify('请填写姓名！');
                                break;
                            }
                            user_name = content[0];
                        }
                        break;
                    default:
                        if(good){
                            if(problems[i].id>0){
                                prob_son = {
                                    answer:[],
                                    id:problems[i].id
                                }
                                let booly = false;
                                content.map((item,index)=>{
                                    if(item.active){
                                        booly = true;
                                        prob_son.answer.push(ABC[index]);
                                    }
                                })
                                if(!booly){
                                    good = false;
                                }
                                answer.push(prob_son);
                            }
                        }
                }
            }

            answer = JSON.stringify(answer);

            face_id = sessionStorage.getItem('faceID');
            tongue_id = sessionStorage.getItem('tongueID');

            let assess = JSON.parse(sessionStorage.getItem('assess'));
            if(assess){
                server_type = assess.server_type;
                user_id = assess.user_id;
                yao_account_id = assess.yao_account_id;
                sys_company_id = Number(assess.sys_company_id);
                
                if(user_id>0){
                    check_type=2
                }
            }

            let equipment = JSON.parse(sessionStorage.getItem('equipment'));
            if(equipment){
                equipment_code = equipment.equipment_code;
                sys_company_id = Number(equipment.sys_company_id);
            }
            
            unique_token = state.unique_token;

            let submitData = {
                age,
                sex,
                birthday,
                height,
                weight,
                face_id,
                tongue_id,
                answer,
                equipment_code,
                sys_company_id,
                unique_token,
                carte_vital,
                id_code,
                user_name,
                server_type,
                check_type,
                user_id,
                yao_account_id
            }
            
            let question_api = sessionStorage.token?setsetReportToken:setReport;
            //如果有手机号
            let phone = sessionStorage.getItem('phone');
            if(phone){
                submitData.phone = phone;
                sessionStorage.removeItem('phone');
            }

            if(bool){
                if(good){
                    //语音
                    audioPaly.play(fenxiing);
                    state.loading = true;
                    axios (`/api${question_api}`, submitData,'POST').then((data)=>{
                        if(data.code==1){
                            state.loading = false;
                            let flow = sessionStorage.getItem('flow');
                            if(flow){
                                let testing_type = JSON.parse(flow).testing_type;
                                if(testing_type==1){
                                    state.token = data.data.token;
                                    state.show = true;
                                }else{
                                    sessionStorage.removeItem('assess');
                                    bindreport(data.data.token)
                                }
                            }else{
                                bindreport(data.data.token)
                            }                       
                        }else{
                            state.loading = false;
                            Notify(data.msg);
                        }
                        
                        function bindreport(token){
                            if(sessionStorage.card){
                                axios (`/kfApi${bind_report}`, {
                                    report_token:token,
                                    card:sessionStorage.card
                                },'POST').then((data)=>{
                                    if(data.code==1){
                                        params._this.$router.push({
                                            name:'report',
                                            params: {
                                                id:token
                                            }
                                        });
                                    }
                                });
                            }else{
                                params._this.$router.push({
                                    name:'report',
                                    params: {
                                        id:token
                                    }
                                });
                            }
                        }
                    });
                }else{
                    Notify('请填写问诊问题！');
                }
            }
        }
    },
    actions: {              //异步方法
        axiosData({commit},params){
            commit('mainAxios',params)
        },
        submitData({commit},params){
            commit('submitAxios',params)
        },
        lastData({commit}){
            commit('lastAxios')
        }
    },
    getters: {},
}

export default device;