import axios from "@/utils/axios";
import { valid, bind_card , user_card , bind_report } from "@/api";
// import domain from "@/utils/config";
// import { getDomain } from "@/utils";
import { Toast } from 'vant';

let code = {
  namespaced: true,
  state: {
    l_state:1         //4:刚进来     1：权益码项目说明    2：开始问诊和说明     3：权益码和说明
  },
  mutations: {
    //验证权益码是否正确
    xlbCode (state, params) {
      axios (`/kfApi${valid}`, params).then ((data) => {
        if(data.code==1){
            //let appid = getDomain();
            //localStorage.setItem('card',params.card);
            sessionStorage.setItem('card',params.card);
            state.l_state = 2;
            // sessionStorage.setItem("isToken",true);
            // window.location.href =  `http://${appid}${domain}?Callback=${encodeURI(window.location.href)}&source=3`;
        }else{
            Toast({
                message:data.msg
            });
        }
      })
    },
    //绑定用户和权益码
    bindCode (state, params) {
        axios (`/kfApi${bind_card}`, params).then ((data) => {
            if(data.code==1){
                state.l_state = 2;
                localStorage.removeItem('card');
            }else{
                Toast({
                    message:data.msg
                });
                state.l_state = 3;
            }
        })
    },
    //通过token来获取用户绑定的权益码
    userInfo (state, params) {
        let card = localStorage.card;
        axios (`/kfApi${user_card}`, params).then ((data) => {
            if(!data.code){ //未绑定
                if(card){   //本地有权益码
                    this.dispatch('xlb/bindData',{
                        card
                    });
                }else{
                    state.l_state = 3;
                    localStorage.removeItem('card');
                }
            }else{
                //已绑定
                //输入的权益码是否与输入的权益码一致
                if(card && (card!==data.data.card)){  //不一致
                    state.l_state = 2;
                    Toast({
                        message:'当前权益码无法与您绑定，因为您已经绑定了另外的权益码！'
                    });
                    localStorage.removeItem('card');
                }else{
                    state.l_state = 2;
                    localStorage.removeItem('card');
                }
            }
        })
    },
    //改变数值
    change2(state,data){
        state[data.key] = data.value;
    },
  },
  actions: {
    //异步方法
    xlbData ({ commit }, params) {
      commit ("xlbCode", params);
    },
    userData ({ commit }, params) {
        commit ("userInfo", params);
    },
    bindData ({ commit }, params) {
        commit ("bindCode", params);
    }
  },
  getters: {},
};

export default code;
